import React, { useEffect, useState } from "react";

import './pageheader.css';
import Footer from "../footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IconButton } from "../styledbutton";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import NavItem from "../dropdown/menuitems";
import { useEntitlements } from "../../../hooks/entitlements";

export default function PageHeader(props) {
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();
    const { isEntitled, entitlements, isLoadingEntitlements } = useEntitlements();
    const maxLabelVisibleWidth = 800;
    const [isLoading, setIsLoading] = useState(true);

    const header = isLoadingEntitlements || !props.entitlement || isEntitled(props.entitlement) ? props.image : '/img/headers/permission-denied.png';

    useEffect(() => {
        setIsLoading(isLoadingEntitlements);
    }, [isLoadingEntitlements]);

    function drawBreadcrumb() {
        if (!props.breadcrumb) return <></>;

        return <div className={"pageheader-breadcrumb"}>
            {props.breadcrumb.map((item, index) =>
                <span key={index}>
                    <Link className={"link"} to={item[1]}>{item[0]}</Link>
                    {index < props.breadcrumb.length - 1 && <span className={"homebread-separator"}>&nbsp;/&nbsp;</span>}
                </span>)}
        </div>
    }

    function drawMenu() {
        return <div className={"create-card-flex-between-container"}
                    style={{ backgroundColor: "#1e1e1e", padding: 8, maxWidth: windowWidth - 72 }}>
            {props.menu()}
        </div>;
    }

    function drawDefaultMenu() {
        return <div className={"create-card-flex-between-container"}
                    style={{ backgroundColor: "#1e1e1e", padding: 8, maxWidth: windowWidth - 72 }}>

            {props.menuleft && props.menuleft.map((item, index) => {
                if (item.dropdown) {
                    return (<NavItem key={index} item={item} />);
                }
                return <IconButton key={index} icon={item.icon} label={windowWidth > maxLabelVisibleWidth ? item.label : undefined} onClick={() => {
                    item.onClick && item.onClick()
                }} />
            })}

            <div className={"create-card-flex-between-items-grow"} />

            {props.menuright && props.menuright.map((item, index) => {
                return <IconButton key={index} icon={item.icon} label={windowWidth > maxLabelVisibleWidth ? item.label : undefined} onClick={() => item.onClick()} />
            })}
        </div>;
    }

    function hasMenu() {
        return (props.menuleft || props.menuright || props.menu);
    }
    function drawPermissionDenied() {
        return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <Helmet>
                <meta property="og:title" content={"AI Art | Error"} />
                <title>{"AI Art | Error"}</title>
            </Helmet>

            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <div className="pageheader-header" style={{ backgroundImage: `url('${header}')`, height: 96 }}>
                    <div className="pageheader-header-container">
                        <div className="pageheader-header-content">
                            <div className={"pageheader-header-title-row"} style={{ maxWidth: windowWidth - 72 }}>
                                <div className="pageheader-left">
                                    <div className={"pageheader-title"}>
                                        Error
                                    </div>
                                    <div className={"pageheader-breadcrumb"}>
                                        {drawBreadcrumb()}
                                    </div>
                                </div>
                                <div className="pageheader-right">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pageheader-content-body" style={{ position: "relative", flexGrow: 1, overflowY: "auto", padding: "16px" }}>
                    Permission Denied.
                </div>
            </div>

            <Footer microfooter={props.microfooter} />
        </div>
    }

    function drawContent() {
        return <div style={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>
            <Helmet>
                <meta property="og:title" content={"AI Art | " + props.title}/>
                {props.description && <meta property="og:description" content={props.description}/>}
                {(props.image || props.preview) && <meta property="og:image" content={props.preview ?? props.image}/>}
                <title>{"AI Art | " + props.title}</title>
            </Helmet>

            <div style={{flexGrow: 1, display: "flex", flexDirection: "column"}}>
                {!props.minimum && <div style={{position: "relative"}}>
                    <div className="pageheader-image-container" style={{backgroundImage: `url('${header}')`}}/>
                </div>}
                {!props.minimum &&
                    <div className="pageheader-spacer" style={{height: 400 - (hasMenu() ? 162 : 96)}}>&nbsp;</div>}
                <div className="pageheader-header"
                     style={{backgroundImage: `url('${header}')`, height: hasMenu() ? 162 : 96}}>
                    <div className="pageheader-header-container">
                        <div className="pageheader-header-content">
                            <div className={"pageheader-header-title-row"} style={{maxWidth: windowWidth - 72}}>
                                <div className="pageheader-left">
                                    <div className={"pageheader-title"}>
                                        {props.title}
                                    </div>
                                    <div className={"pageheader-breadcrumb"}>
                                        {drawBreadcrumb()}
                                    </div>
                                </div>
                                <div className="pageheader-right">

                                </div>
                            </div>
                            {hasMenu() && ((typeof props.menu) === 'function' ? drawMenu() : drawDefaultMenu())}
                        </div>
                    </div>
                </div>
                <div className="pageheader-content-body"
                     style={{position: "relative", flexGrow: 1, overflowY: "auto", padding: "16px"}}>
                    {!props.entitlement || isEntitled(props.entitlement) ?
                        <>{props.children}</> :
                        <>
                            {!isLoadingEntitlements ? "Permission Denied." : ""}
                        </>
                    }
                </div>
            </div>

            <Footer microfooter={props.microfooter}/>
        </div>
    }

    return !props.entitlement || isEntitled(props.entitlement) ? drawContent() : drawPermissionDenied();
}
