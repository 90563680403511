import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { MdAdd, MdEdit, MdDelete } from "react-icons/all";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import { Table, Button, Modal as BootstrapModal, Form, Pagination } from 'react-bootstrap';
import {useEndpoint} from "../../../hooks/api";
import {toast} from "react-toastify";
import SongSelectionModal from "./components/SongSelectionModel";

const MusicPlaylist = () => {
    const navigate = useNavigate();
    const [headerImage, setHeaderImage] = React.useState('/img/headers/music.png');
    const { id: playlistId } = useParams();
    const {fetchAuth: addSong} = useEndpoint("music/playlists/add_song");
    const {fetchAuth: listSongs} = useEndpoint("music/playlists/list_songs");
    const {fetchAuth: removeSong} = useEndpoint("music/playlists/remove_song");
    const {fetchAuth: fetchPlaylist} = useEndpoint("music/playlists/playlist");

    const [playlistSongs, setPlaylistSongs] = useState([]);
    const [availableSongs, setAvailableSongs] = useState([]);
    const [filteredSongs, setFilteredSongs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [songsPerPage] = useState(10);
    const [playlistName, setPlaylistName] = useState("My Playlist");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const loadSongs = async () => {
        try {
            const response = await listSongs({ id: playlistId });
            setPlaylistSongs(response);
            console.log(response);
        } catch (error) {
            toast.error("Failed to load songs.");
        }
    };

    // Fetch playlist songs when component loads
    useEffect(() => {
        loadSongs();
    }, [playlistId]);

    const handleAddPlaylist = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        setFilteredSongs(availableSongs.filter(song => song.title.toLowerCase().includes(term.toLowerCase())));
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAddSelectedSongs = async () => {
        // Add selected songs to the playlist
        const selectedSongIds = filteredSongs.filter(song => song.selected).map(song => song.id);
        if (selectedSongIds.length === 0) {
            toast.error("No songs selected to add.");
            return;
        }
        try {
            await addSong({ playlistId, songIds: selectedSongIds });
            toast.success("Songs added successfully.");
            setIsModalOpen(false);
        } catch (error) {
            toast.error("Failed to add songs.");
        }
    };

    const handleSelectSong = (songId) => {
        setFilteredSongs(filteredSongs.map(song => {
            if (song.id === songId) {
                return { ...song, selected: !song.selected };
            }
            return song;
        }));
    };

    function content() {
        return (
            <Table striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Audio</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {playlistSongs?.map((song, index) => (
                    <tr key={song.id}>
                        <td>{index + 1}</td>
                        <td>{song.title}</td>
                        <td>
                            <audio controls>
                                <source src={song.mp3_url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </td>
                        <td>
                            <Button variant="outline-danger" size="sm" onClick={() => handleRemoveSong(song.id)}>
                                <MdDelete />
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        );
    }

    const handleRemoveSong = async (songId) => {
        try {
            await removeSong({ song_id: songId, id: playlistId });
            setPlaylistSongs(playlistSongs.filter(song => song.id !== songId));
            toast.success("Song removed successfully.");
        } catch (error) {
            toast.error("Failed to remove song.");
        }
    };

    const indexOfLastSong = currentPage * songsPerPage;
    const indexOfFirstSong = indexOfLastSong - songsPerPage;
    const currentSongs = filteredSongs.slice(indexOfFirstSong, indexOfLastSong);

    return (
        <PageHeader image={headerImage}
                    title={`Playlist - ${playlistName}`}
                    description={"Manage your music playlists effortlessly."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["Music", "/music"],
                        ["Playlists", "/music/playlists"]
                    ]}
                    minimum={true}
                    menuleft={[
                        {icon: MdAdd, label: "Add Song", onClick: handleAddPlaylist},
                    ]}
                    menuright={[]}>
            {content()}
            <SongSelectionModal playlistSongs={playlistSongs} onSongAdded={() => loadSongs()} isModalOpen={isModalOpen} onHide={() => {
                setIsModalOpen(false);
                loadSongs();
            }} />
        </PageHeader>
    );
};

export default MusicPlaylist;
