// src/PresetGrid.js
import React, { useState } from 'react';
import './presetgrid.css';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

function PresetGrid({ presets }) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    // Categorize presets based on the prefix before " - ", or put them in 'General' if no prefix exists
    const categorizedPresets = presets.reduce((categories, preset) => {
        const [category, title] = preset.label.includes(' - ') ? preset.label.split(' - ') : ['General', preset.label];
        if (!categories[category]) {
            categories[category] = [];
        }
        categories[category].push({ ...preset, title });
        return categories;
    }, {});

    // Sort categories and presets by name, keeping 'General' as the first entry
    const sortedCategories = Object.keys(categorizedPresets).sort((a, b) => {
        if (a === 'General') return -1;
        if (b === 'General') return 1;
        return a.localeCompare(b);
    });

    const filteredCategories = sortedCategories.reduce((filtered, category) => {
        const filteredPresets = categorizedPresets[category]
            .filter(preset => preset.label.toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((a, b) => a.label.localeCompare(b.label));
        if (filteredPresets.length > 0) {
            filtered[category] = filteredPresets;
        }
        return filtered;
    }, {});

    function handleClick(preset) {
        if (preset.link) navigate(preset.link);
        if (preset.onClick) preset.onClick();
    }

    return (
        <div className="preset-grid">
            <Form.Control
                type="text"
                placeholder="Search presets..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="mb-3" // Bootstrap margin-bottom class
            />
            {Object.keys(filteredCategories).map((category, index) => (
                <div key={index} className="category-section" style={{marginBottom: 16}}>
                    <h4 className="category-title">{category}</h4>
                    <div className="grid">
                        {filteredCategories[category].map((preset, presetIndex) => (
                            <div key={presetIndex} className="preset-box" onClick={() => handleClick(preset)}>
                                <div className={"preset-box-content"}>
                                    <h3 className="preset-title">{preset.label}</h3>
                                    <p className="preset-description">{preset.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PresetGrid;
