import React, {forwardRef, useImperativeHandle, useEffect, useState} from 'react';
import {Form, Button, Table, Card, ListGroup} from 'react-bootstrap';
import {MdAdd, MdDelete, MdFileCopy, MdLink, MdNewLabel} from 'react-icons/md';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync, useAuthenticatedArtApi} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import ObfuscatedKey from "../obfuscatedkey";
import CodePreview from "../codepreview/codepreview";
import PromptVariables from "./promptvariables";
import {IoMdGlobe} from "react-icons/all";
import {useNavigate} from "react-router-dom";

function ArtApiKeyList({preset}) {
    const { getAccessTokenSilently, user } = useAuth0();
    const navigate = useNavigate();

    const [ apiKeys, setApiKeys ] = React.useState([]);
    const [ createApiKey, setCreateApiKey ] = React.useState(false);
    const [ apiKeyDescription, setApiKeyDescription ] = React.useState('');
    const [postData, setPostData] = React.useState('');
    const [rawPostData, setRawPostData] = React.useState({});

    async function fetchApiKeys() {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const apiKeyResponse = await artApiFetchAuthAsync(token, "art-api/keys/list", "id=" + preset.id);
        if(apiKeyResponse ) {
            setApiKeys(apiKeyResponse);
        }
    }
    useEffect(() => {
        if(!preset) return;

        fetchApiKeys();
    }, [preset]);

    const transformToStars = (text) => {
        return text.replace(/[A-Za-z0-9]/g, '*');
    };

    function copyUrl(apiKey) {
        navigator.clipboard.writeText("https://api.aiart.doubtech.com/art-api/job?key=" + apiKey + "&prompt=");
        toast.success("URL copied to clipboard");
    }

    function copyWebUrl(apiKey) {
        window.open("/preset/" + apiKey, "_blank");
    }

    function handleCreateApiKey(event) {
        event.preventDefault();
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "art-api/keys/keygen",
                "id=" + preset.id,
                "description=" + apiKeyDescription);
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(`Failed to create API key for preset ${preset.name}.\n${messageResponse.error}`);
            } else {
                toast.success("API key created.");
                setApiKeyDescription('');
                setCreateApiKey(false);
            }
            await fetchApiKeys();
        }

        fetchResponse();
    }
    function deleteKey(apiKey) {
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "art-api/keys/delete", "key=" + apiKey);
            if(messageResponse) {
                await fetchApiKeys();
            }
        }

        fetchResponse();
    }
    
    function renderApiKeys() {
        function copyKey(apiKey) {
            navigator.clipboard.writeText(apiKey);
            toast.success("API Key copied to clipboard");
        }

        function createCurlPost(apiKey, hide) {
            return `curl -H "Authorization: Bearer ${hide ? transformToStars(apiKey) : apiKey}" \\
                -H "Content-Type: application/json" \\
                -d "${postData}" \\
                "https://api.aiart.doubtech.com/art-api/job"`;
        }

        function createPythonCurlPost(apiKey, hide) {
            const transformedKey = hide ? '***' : apiKey;
            return `import requests

headers = {
    'Authorization': 'Bearer ${transformedKey}',
    'Content-Type': 'application/json',
}

data = ${JSON.stringify(rawPostData)}

response = requests.post('https://api.aiart.doubtech.com/art-api/job', headers=headers, json=data)
print(response.text)`;
        }

        function createPhpCurlPost(apiKey, hide) {
            const transformedKey = hide ? '***' : apiKey;
            return `<?php

$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, 'https://api.aiart.doubtech.com/art-api/job');
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS, "${postData}");
curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  'Authorization: Bearer ${transformedKey}',
  'Content-Type: application/json',
));

$response = curl_exec($ch);
if (curl_errno($ch)) {
    echo 'Error:' . curl_error($ch);
}
curl_close($ch);

echo $response;
?>`;
        }


        function prepGet(apikey, hide) {
            return `curl -H "Authorization: Bearer ${hide ? transformToStars(apikey) : apikey}" "https://api.aiart.doubtech.com/art-api/job?prompt=${encodeURIComponent(rawPostData?.prompt)}"`;
        }

        return <Card className={"mt-5"}>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>API Keys</span>
                    <MdNewLabel className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                        preset.id ? setCreateApiKey(!createApiKey) : setCreateApiKey(false);
                    }} />
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    {/*prepPost()*/}
                </ListGroup.Item>
                {apiKeys && apiKeys.map(key =>
                    <ListGroup.Item style={{cursor: "pointer"}}
                                    key={key.apikey}>
                        <div><div className={"create-card-flex-between-container"}>
                            {key.description}
                            <div className={"create-card-flex-between-items-grow"}/>
                            <IoMdGlobe className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {copyWebUrl(key.apikey)}} />
                            <MdLink className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {copyUrl(key.apikey)}} />
                            <MdDelete className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {deleteKey(key.apikey)}} />
                            <MdFileCopy className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {copyKey(key.apikey)}} />


                        </div>
                            <br/>
                            <CodePreview title={"GET"} language={"bash"} className={"mt-5"} clipboardCode={prepGet(key.apikey, false)}>
                                {prepGet(key.apikey, true)}
                            </CodePreview>
                            <CodePreview title={"POST"} language={"bash"} className={"mt-5"}
                                         clipboardCode={createCurlPost(key.apikey, false)}>
                                {createCurlPost(key.apikey, true)}
                            </CodePreview>

                            <CodePreview title={"POST"} language={"python"} className={"mt-5"}
                                         clipboardCode={createPythonCurlPost(key.apikey, false)}>
                                {createPythonCurlPost(key.apikey, true)}
                            </CodePreview>

                            <CodePreview title={"POST"} language={"php"} className={"mt-5"}
                                         clipboardCode={createPhpCurlPost(key.apikey, false)}>
                                {createPhpCurlPost(key.apikey, true)}
                            </CodePreview>
                        </div>
                    </ListGroup.Item>)}
            </ListGroup>
        </Card>
    }

    function prepVariableData(variables) {
        const variableData = {};
        variables.forEach((variable) => {
            if(variable.value) variableData[variable.name] = variable.value;
        });
        setPostData(JSON.stringify(variableData));
        setRawPostData(variableData);
    }

    return <>
        <PromptVariables preset={preset} onVariablesChanged={prepVariableData} />
        {renderApiKeys()}
        {createApiKey && <Card>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>Create API Key</span>
                </div>
            </Card.Header>

            <Card.Body>
                <Form onSubmit={handleCreateApiKey}>
                    <Form.Group controlId="content">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control value={apiKeyDescription} onChange={(event) => setApiKeyDescription(event.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Form>
            </Card.Body>
        </Card>}
        </>
}

export default ArtApiKeyList;
