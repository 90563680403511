import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Team from './pages/team';
import Price from './pages/price';
import Works from './pages/works';
import Service from './pages/service';
import Contact from './pages/contact';
import ModelHome from "./pages/model";
import Jobs from "./pages/jobs";
import Job from "./pages/job";
import WebGL from "./pages/webgl";
import Login from "./pages/login"
import Signup from "./pages/signup"
import {AuthProvider} from "../hooks/authcontext";
import Profile from "./pages/profile";
import UpdateProfile from "./pages/updateprofile";
import ForgotPassword from "./pages/forgotpassword";
import Auth0ProviderWithHistory from "../auth/auth0-provider-with-history";
import ProtectedComponent from "./components/auth0protectedroute";
import DreamHome from "./pages/dream";
import Device from "./pages/device";
import CCGCards from "./pages/ccg/cards";
import CCGTemplates from "./pages/ccg/templates";
import CCGCreateCard from "./pages/ccg/createcard";
import CCGLayers from "./pages/ccg/layers";
import Sidebar from "./menu/sidebar";
import useWindowDimensions from "../hooks/WindowDimensions";
import CCGCardPrinter from "./pages/ccg/printcards";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {LoadingProvider} from "./components/loading";
import Admin from "./pages/admin";
import Users from "./pages/users";
import {ErrorProvider} from "./components/error";
import CCGDeck from "./pages/ccg/deck";
import CCGDecks from "./pages/ccg/decks";
import TagsHome from "./pages/tags";
import TagGallery from "./pages/tag";
import ImageGrid from "./pages/grid";
import PromptDesign from "./pages/promptdesign";
import AgentDesign from "./pages/gpt/agent";
import AgentChat from "./pages/gpt/chat";
import Presets from "./pages/presets";
import ImageViewer from "./components/imageviewer";
import SetHome from "./pages/set";
import GptHome from "./pages/gpthome";
import DownloadAdminPage from "./pages/admin/downloadadmin";
import SetsHome from "./pages/sets";
import AnimatedGallery from "./pages/animatedgallery";
import {IndexDBProvider} from "./providers/indexdb";
import ImageSelection from "./providers/selection";
import LoraTraining from "./pages/training/lora";
import AgentDataBuilder from "./pages/gpt/databuilder";
import Preset from "./pages/preset/preset";
import ProxyAdminPage from "./pages/admin/proxies/proxy";
import GalleryLauncher from "./pages/gallerylauncher";
import WorkflowViewer from "./pages/workflow-viewer";
import Runpod from "./pages/hive/runpod";
import AgentInfo from "./components/agent/agentinfo/AgentInfo";
import LoadingAuthUpdater from "./pages/LoadingAuthUpdater";
import MusicAnalysis from "./pages/music/music_analysis";
import MusicPlaylists from "./pages/music/playlists";
import AgentCanvas from "./pages/gpt/canvas";
import MusicPlaylist from "./pages/music/playlist";

require("prerender-node").set("prerenderToken", 'sq73cKD7HgyQ41Rug2aW')

export const ScrollTop = ({children, location}) => {
    React.useEffect(() => window.scrollTo(0, 0), [location])
    return children
}
export const CombinedProvider = ({children}) => {
    return (
        <IndexDBProvider>
            <ImageSelection>
                <ErrorProvider>
                    <LoadingProvider>
                        <Auth0ProviderWithHistory>
                            <AuthProvider>
                                <LoadingAuthUpdater />
                                {children}
                            </AuthProvider>
                        </Auth0ProviderWithHistory>
                    </LoadingProvider>
                </ErrorProvider>
            </ImageSelection>
        </IndexDBProvider>
    );
};

export default () => {
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const [error, setError] = React.useState(null);

    window.addEventListener('unhandledrejection', function (event) {
        toast.error(event.reason);
    });

    // Check if there is a url query paramater chromeless
    const urlParams = new URLSearchParams(window.location.search);
    const chromeless = urlParams.get('chromeless');

    return (
        <div className="wraper">
            <CombinedProvider>
                {!chromeless && <Sidebar/>}
                <div style={{marginLeft: 64}}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/model/:model" element={<ModelHome/>}/>
                        <Route path={"/gallery"} element={<GalleryLauncher/>}/>
                        <Route path="/sets" element={<SetsHome/>}/>
                        <Route path="/set/:set" element={<SetHome/>}/>
                        <Route path="/grid/:id/:index" element={<ImageGrid/>}/>
                        <Route path="/grid/:id" element={<ImageGrid/>}/>
                        <Route path="/tags" element={<TagsHome/>}/>
                        <Route path="/tags/*" element={<TagGallery/>}/>
                        <Route path="/about" element={<About/>}/>

                        <Route path="/hive/jobs" element={<Jobs/>}/>
                        <Route path="/hive/runpod" element={<ProtectedComponent component={Runpod}/>}/>

                        <Route path="/job/:job" element={<Job/>}/>
                        <Route path="/job/current/:node" element={<Job/>}/>
                        <Route path="/team" element={<Team/>}/>
                        <Route path="/price" element={<Price/>}/>
                        <Route path="/works" element={<Works/>}/>
                        <Route path="/service" element={<Service/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/webgl" element={<WebGL/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/signup" element={<Signup/>}/>
                        <Route path="/admin"
                               element={<ProtectedComponent component={Admin}/>}/>
                        <Route path="/admin/downloads"
                               element={<ProtectedComponent component={DownloadAdminPage}/>}/>
                        <Route path="/admin/proxies"
                               element={<ProtectedComponent component={ProxyAdminPage}/>}/>
                        <Route path="/users" element={<Users/>}/>
                        <Route path="/image/:id" element={<ImageViewer/>}/>
                        <Route path="/prompt-design" element={<PromptDesign/>}/>
                        <Route path="/gpt/agent"
                               element={<ProtectedComponent component={AgentDesign}/>}/>
                        <Route path="/gpt/agents"
                               element={<ProtectedComponent component={AgentDesign}/>}/>
                        <Route path="/gpt/agents/:id"
                               element={<ProtectedComponent component={AgentInfo}/>}/>
                        <Route path="/gpt/agents/:id/:tab"
                               element={<ProtectedComponent component={AgentInfo}/>}/>
                        <Route path="/gpt/agent/:id"
                               element={<ProtectedComponent component={AgentInfo}/>}/>
                        <Route path="/gpt/agent/:id/:tab"
                               element={<ProtectedComponent component={AgentInfo}/>}/>
                        <Route path="/gptinfo" element={<GptHome/>}/>
                        <Route path="/gpt/chat" element={<AgentChat/>}/>
                        <Route path="/gpt/chat/:key" element={<AgentChat/>}/>
                        <Route path="/gpt/canvas" element={<AgentCanvas/>}/>
                        <Route path="/gpt/canvas/:key" element={<AgentCanvas/>}/>
                        <Route path="/gpt/databuilder" element={<AgentDataBuilder/>}/>
                        <Route path="/profile" element={<ProtectedComponent component={Profile}/>}/>
                        <Route path="/update-profile"
                               element={<ProtectedComponent component={UpdateProfile}/>}/>
                        <Route path="/dream" element={<ProtectedComponent component={DreamHome}/>}/>
                        <Route path="/workflow-viewer" element={<WorkflowViewer/>}/>
                        <Route path="/presets" element={<ProtectedComponent component={Presets}/>}/>
                        <Route path="/preset/:key" element={<Preset/>}/>
                        <Route path="/presets/:id" element={<ProtectedComponent component={Presets}/>}/>
                        <Route path="/presets/:id/:tab" element={<ProtectedComponent component={Presets}/>}/>
                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/device" element={<Device/>}/>
                        <Route path="/activate" element={<Device/>}/>
                        <Route path="/music/analysis"
                               element={<ProtectedComponent component={MusicAnalysis}/>}/>
                        <Route path="/music/playlists"
                               element={<ProtectedComponent component={MusicPlaylists}/>}/>
                        <Route path="/music/playlist/:id"
                               element={<ProtectedComponent component={MusicPlaylist}/>}/>
                        <Route path="/ccg/cards" element={<CCGCards/>}/>
                        <Route path="/ccg/card-printer"
                               element={<ProtectedComponent component={CCGCardPrinter}/>}/>
                        <Route path="/ccg/templates"
                               element={<ProtectedComponent component={CCGTemplates}/>}/>
                        <Route path="/ccg/templates/:template"
                               element={<ProtectedComponent component={CCGTemplates}/>}/>
                        <Route path="/ccg/layers"
                               element={<ProtectedComponent component={CCGLayers}/>}/>
                        <Route path="/ccg/layers/:layers"
                               element={<ProtectedComponent component={CCGLayers}/>}/>
                        <Route path="/ccg/create-card"
                               element={<ProtectedComponent component={CCGCreateCard}/>}/>
                        <Route path="/ccg/create-card/:card"
                               element={<ProtectedComponent component={CCGCreateCard}/>}/>
                        <Route path="/ccg/decks" element={<CCGDecks/>}/>
                        <Route path="/ccg/deck" element={<ProtectedComponent component={CCGDeck}/>}/>
                        <Route path="/ccg/deck/:deck-id"
                               element={<ProtectedComponent component={CCGDeck}/>}/>
                        <Route path="/animated-gallery" element={<AnimatedGallery/>}/>
                        <Route path="/training/lora" element={<LoraTraining/>}/>
                    </Routes>
                </div>
            </CombinedProvider>
            <ToastContainer theme="dark"/>
        </div>)
}
