import { useState, useEffect, useCallback } from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync} from "../../../hooks/artapi";
import {useEndpoint, useToken} from "../../../hooks/api";

function useAgents() {
    const [agents, setAgents] = useState({});
    const [namespaces, setNamespaces] = useState([]);
    const [isLoadingAgents, setIsLoadingAgents] = useState(true);
    const [error, setError] = useState(null);
    const [agentCount, setAgentCount] = useState(0);
    const { fetchAuth: listAgents } = useEndpoint("gpt/list-agents");
    const { fetchAuth: listNamespaces } = useEndpoint("gpt/list-namespaces");

    async function fetchAgentsAsync() {
        try {
            const agentResponse = await listAgents();
            if (agentResponse) {
                agentResponse.sort((a, b) => {
                    if (a.namespace < b.namespace) return -1;
                    if (a.namespace > b.namespace) return 1;
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                });
                let agentsMap = {};
                for (let i = 0; i < agentResponse.length; i++) {
                    if (!agentsMap[agentResponse[i].namespace]) agentsMap[agentResponse[i].namespace] = [];
                    agentsMap[agentResponse[i].namespace].push(agentResponse[i]);
                }
                setAgents(agentsMap);
                setAgentCount(agentResponse.length);
            }
        } catch (e) {
            setError(e);
        }
    }

    async function fetchNamespacesAsync() {
        try {
            const namespaceResponse = await listNamespaces();
            if (namespaceResponse) {
                if(namespaceResponse.error) {
                    setError(namespaceResponse.error);
                    return;
                }

                // If Namespaces isn't an array don't set it
                if (Array.isArray(namespaceResponse)) {
                    // Sort the namespaces by namespace.name
                    setNamespaces(namespaceResponse.sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    }));
                }
            }
        } catch (e) {
            setError(e);
        }
    }

    const fetchNamespacesCallback = useCallback(async () => {
        return await fetchNamespacesAsync();
    }, []);

    const fetchAgentsCallback = useCallback(async () => {
        return await fetchNamespacesAsync();
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoadingAgents(true);
                console.log("Fetching agents...");
                await fetchAgentsAsync();
                console.log("Fetching namespaces...");
                await fetchNamespacesAsync();
                setIsLoadingAgents(false);
                console.log("Finished fetching agents and namespaces.");
            } catch (e) {
                setError(e);
            }
        }

        fetchData();
    }, []);

    return { agents, agentCount, namespaces, isLoadingAgents, error, fetchNamespaces: fetchNamespacesCallback, fetchAgents: fetchAgentsCallback };
}

export default useAgents;
