import React, { useEffect, useRef, useState } from "react";
import { ChatContainer, MainContainer, MessageInput, MessageList } from "@chatscope/chat-ui-kit-react";
import ChatBubble from "./chatbubble";
import useAgentChat from "../../pages/gpt/chathook";
import { useAuth0 } from "@auth0/auth0-react";
import AutoScrollingDiv from "../../pages/gpt/AutoScrollingDiv";
import { Button, Form } from "react-bootstrap";
import { MdSend, MdHistory } from "react-icons/md";
import StyledButton from "../styledbutton";

function AgentChatWindow({ agent, agentKey, height, scroll = false, showBackground = true, onPostProcessMessage=(message) => message  }) {
    const { setAgent, setAgentKey, submitPrompt, messages, deleteMessage, messageCount, resetSubmittedHistory } = useAgentChat({ agent, agentKey });
    const { user } = useAuth0();
    const scrollingDivRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [includeHistory, setIncludeHistory] = useState(true);

    useEffect(() => { setAgent(agent); }, [agent]);
    useEffect(() => { setAgentKey(agentKey); }, [agentKey]);

    useEffect(() => {
        if (scrollingDivRef.current) {
            scrollingDivRef.current.scrollToBottom();
        }
    }, [messages, agent, agentKey, messageCount]);

    function drawChatHistory() {
        return <div style={{ padding: "20px" }}>
            {messages && messages.map((message, index) =>
                <ChatBubble
                    key={index}
                    message={message}
                    user={{ avatar: message.direction === "incoming" ? "" : user?.picture }}
                    onResend={message => sendPrompt(message.content)}
                    onDelete={message => {
                        deleteMessage(message);
                    }}
                    onPostProcessMessage={onPostProcessMessage}
                />)}
        </div>;
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendPrompt(inputValue);
        }
    };

    const handleSendClick = () => {
        sendPrompt(inputValue);
    };

    const handleResetHistoryClick = () => {
        resetSubmittedHistory();
    };

    const sendPrompt = (inputValue) => {
        if (inputValue.trim() !== "") {
            submitPrompt(inputValue, includeHistory);
            setInputValue("");
        }
    }

    return (
        <MainContainer style={showBackground ? {
            backgroundColor: "#252424",
            borderRadius: "20px"
        } : {}}>
            {scroll ? <AutoScrollingDiv autoscroll={false} height={height}
                                        ref={scrollingDivRef}>{drawChatHistory()}</AutoScrollingDiv>
                : <div>{drawChatHistory()}</div>
            }
            <ChatContainer className={"mt-3"}>
                <MessageList>

                </MessageList>
            </ChatContainer>
            <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                <div style={{ flexGrow: 1 }}>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Type message here..."
                        style={{ resize: "none" }}
                    />
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: 16 }}>
                <Form.Check
                    type="checkbox"
                    label="Include Conversation History"
                    checked={includeHistory}
                    onChange={(e) => setIncludeHistory(e.target.checked)}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledButton variant="secondary" onClick={handleResetHistoryClick} style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                        <MdHistory style={{ marginRight: "5px" }} /> Reset History
                    </StyledButton>
                    <StyledButton variant="primary" onClick={handleSendClick} style={{ display: "flex", alignItems: "center" }}>
                        <MdSend style={{ marginRight: "5px" }} /> Send
                    </StyledButton>
                </div>
            </div>
        </MainContainer>
    );
}

export default AgentChatWindow;
