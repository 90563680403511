import React, {useCallback, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import {MdAdd, MdUpload} from "react-icons/all";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import 'reactflow/dist/style.css';
import { Form, Button, Modal, Table } from 'react-bootstrap';
import {useEndpoint} from "../../../hooks/api";
import {toast} from "react-toastify";
import {JsonEditor} from "jsoneditor-react18";
import {useLoading} from "../../components/loading";

const MusicAnalysis = () => {
    const navigate = useNavigate();
    const [headerImage, setHeaderImage] = React.useState('/img/headers/music.png');
    const { postBodyAuth: postAnalyze } = useEndpoint("music/analyze", {contentType: "application/json"});
    const [title, setTitle] = React.useState("");
    const [lyrics, setLyrics] = React.useState("");
    const [audioFile, setAudioFile] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [pastAnalysis, setPastAnalysis] = React.useState([]);
    const [showLyricsModal, setShowLyricsModal] = React.useState({ show: false, lyrics: "" });
    const [showAnalysisModel, setShowAnalysisModel] = React.useState({ show: false, analysis: {} });
    const { fetchAuth: getAnalysisList } = useEndpoint("music/list");
    const { fetchAuth: getAnalysisData } = useEndpoint("music/get-analysis");
    const editorRef = useRef(null);
    const {isAuthenticated} = useLoading();

    useEffect(() => {
        if(!isAuthenticated) return;

        const fetchAnalysis = async () => {
            const response = await getAnalysisList();
            console.log("Response: ", response);
            if (response && !response.error) {
                setPastAnalysis(response);
            } else if (response && response.error) {
                toast.error("Failed to fetch past analysis. " + response.error);
            } else {
                toast.error("Failed to fetch past analysis.");
            }
        };
        fetchAnalysis();
    }, [isAuthenticated]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'audio/wav' || file.type === 'audio/mpeg')) {
            setAudioFile(file);
        } else {
            toast.error("Please upload a valid .wav or .mp3 file.\nFile type: " + file.type);
        }
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleLyricsChange = (event) => {
        setLyrics(event.target.value);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleAnalyze = async () => {
        if (audioFile) {
            try {
                toast.info("Uploading file and analyzing, please wait...");
                const reader = new FileReader();
                reader.onload = async () => {
                    const base64Audio = reader.result.split(",")[1];
                    const requestBody = {
                        "title": title,
                        "lyrics": lyrics,
                        "output_audio_format": "wav",
                        "mime": audioFile.type,
                        "data": base64Audio,
                        "upload_mp3": true
                    };

                    const response = await postAnalyze(requestBody);

                    console.log("Response: ", response);
                    if (response && !response.error) {
                        toast.success("File analyzed successfully");
                        setPastAnalysis([...pastAnalysis, {title, lyrics, mp3_url: reader.result}]);
                        handleCloseModal();
                    } else if (response && response.error) {
                        toast.error("Failed to analyze file. " + response.error);
                    } else {
                        toast.error("Failed to analyze file. ");
                    }
                };
                reader.readAsDataURL(audioFile);
            } catch (error) {
                toast.error("Error analyzing file: " + error.message);
            }
        } else {
            toast.error("Please upload a valid audio file.");
        }
    };

    const handleShowLyricsModal = (lyrics) => setShowLyricsModal({ show: true, lyrics });
    const handleCloseLyricsModal = () => setShowLyricsModal({ show: false, lyrics: "" });
    const handleCloseAnalysisModal = () => setShowAnalysisModel({ show: false, analysis: {} });

    function viewAnalysis(analysis, type=undefined) {
        console.log("Viewing analysis: ", analysis);
        if(!analysis) return;
        const data = {"Loading": "Analysis data..."};

        async function fetchAnalysisData() {
            console.log("Fetching analysis data...", analysis);
            let data = await getAnalysisData({"id": analysis.id});
            console.log("Analysis data: ", data);
            if('error' in data) {
                setShowAnalysisModel({ show: false, analysis: data });
                toast.error("Failed to fetch analysis data: " + data.error);
                return;
            }
            try {
                if(type !== undefined) {
                    // Filter the data based on the type. Data is an array of objects with type fields
                    data = data.filter((item) => item.type === type);
                    // Alter the structure to be a dictionary with the time as the key and the value the data field
                    let newData = {};
                    data.forEach((item) => {
                        newData[item.time] = item.data;
                    });
                    data = newData;
                }
                editorRef.current.jsonEditor.set(data);
            } catch (e) {
                toast.error("Failed to fetch analysis data: " + e.toString());
            }
        }
        toast.info("Fetching analysis data, please wait...");
        setShowAnalysisModel({ show: true, analysis: data });
        fetchAnalysisData();
    }

    function content() {

        return (
            <>
                <Table striped bordered hover variant="dark">
                    <thead className="table-dark">
                    <tr>
                        <th>Title</th>
                        <th>First Line of Lyrics</th>
                        <th>Audio Player</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pastAnalysis.map((analysis, index) => (
                        <tr key={index}>
                            <td>{analysis.title}</td>
                            <td>{analysis.lyrics.split('\n')[0]}</td>
                            <td>
                                <audio controls className="audio-player">
                                    <source src={analysis.mp3_url} type={analysis.mime} />
                                    Your browser does not support the audio element.
                                </audio>
                            </td>
                            <td>
                                <Button variant="outline-info" onClick={() => handleShowLyricsModal(analysis.lyrics)} className="me-2">
                                    View Full Lyrics
                                </Button>
                                <Button variant="outline-primary" className="me-2" onClick={() => {
                                    console.log("Viewing analysis: ", analysis);
                                    viewAnalysis(analysis)
                                }}>
                                    View Analysis
                                </Button>
                                <Button variant="outline-primary" className="me-2" onClick={() => {
                                    console.log("Viewing analysis: ", analysis);
                                    viewAnalysis(analysis, "VISEME")
                                }}>
                                    View Visemes
                                </Button>
                                <Button variant="outline-primary" className="me-2" onClick={() => {
                                    console.log("Viewing analysis: ", analysis);
                                    viewAnalysis(analysis, "EMOTE")
                                }}>
                                    View Emotes
                                </Button>
                                <Button variant="outline-success" href={analysis.mp3_url} download={analysis.title}>
                                    Download
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Modal show={showLyricsModal.show} onHide={handleCloseLyricsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Full Lyrics</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showLyricsModal.lyrics.split("\n").map((line, index) => (
                            <span key={index}>
                {line}
                                <br />
            </span>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseLyricsModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Modal
                    show={showAnalysisModel.show}
                    onHide={handleCloseAnalysisModal}
                    style={{ maxWidth: "75vw" }}
                    dialogClassName="modal-75-screen"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Full Lyrics</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: "75vh", overflowY: "auto" }}>  // Height and scroll
                        <JsonEditor ref={editorRef} mode="view" style={{ maxHeight: "75vh"}}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAnalysisModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }

    function rightColumn() {
        return <></>
    }

    return (
        <PageHeader image={headerImage}
                    title={"Music Analysis"}
                    description={"Analyze music for transcriptions, visemes, beat, etc"}
                    breadcrumb={[
                        ["Home", "/"],
                        ["Music", "/music"]
                    ]}
                    menuleft={[
                        {icon: MdUpload, label: "Upload", onClick: handleShowModal},
                    ]}
                    entitlement={"music"}
                    menuright={[]}>

            {content()}

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Audio File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="titleInput">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                placeholder="Enter title here..."
                            />
                        </Form.Group>
                        <Form.Group controlId="lyricsTextarea" className="mt-3">
                            <Form.Label>Lyrics</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={lyrics}
                                onChange={handleLyricsChange}
                                placeholder="Enter lyrics here..."
                            />
                        </Form.Group>
                        <Form.Group controlId="fileUpload" className="mt-3">
                            <Form.Label>Upload Audio File (wav or mp3)</Form.Label>
                            <Form.Control
                                type="file"
                                accept="audio/wav, audio/mp3"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAnalyze}>
                        Analyze
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageHeader>
    )
}

export default MusicAnalysis;
