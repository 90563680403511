import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal as BootstrapModal, Form, Table, Pagination, Button } from 'react-bootstrap';
import {useEndpoint} from '../../../../hooks/api';
import {toast} from "react-toastify";

const SongSelectionModal = ({ playlistSongs, onSongAdded, isModalOpen, onHide }) => {
    const { id: playlistId } = useParams();
    const { fetchAuth: addSong } = useEndpoint('music/playlists/add_song');
    const { fetchAuth: listSongs } = useEndpoint('music/playlists/list_songs');

    const [availableSongs, setAvailableSongs] = useState([]);
    const [filteredSongs, setFilteredSongs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [songsPerPage] = useState(10);
    const [selectedSongs, setSelectedSongs] = useState(new Set());
    const [selectedCount, setSelectedCount] = useState(0);

    useEffect(() => {
        // Fetch available songs here
        listSongs().then(response => {
            if(response.error) {
                return;
            }
            const filteredAvailableSongs = response.filter(song =>
                !playlistSongs.some(playlistSong => playlistSong.id === song.id)
            );
            setAvailableSongs(filteredAvailableSongs);
            setFilteredSongs(filteredAvailableSongs);
        });
    }, [playlistId]);

    useEffect(() => {
        // Update filtered songs whenever available songs or search term changes
        setFilteredSongs(
            availableSongs.filter(song =>
                song.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [availableSongs, searchTerm]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAddSelectedSongs = async () => {
        for (const songId of selectedSongs) {
            let result = await addSong({ id: playlistId, song_id: songId });
            if(result.error) {
                toast.error(result.error);
                return;
            }
            onSongAdded(songId);
        }
        // Refresh the playlist or handle other logic after adding songs
        if(onHide) onHide();
    };

    const toggleSongSelection = (songId) => {
        const newSelectedSongs = new Set(selectedSongs);
        if (newSelectedSongs.has(songId)) {
            newSelectedSongs.delete(songId);
        } else {
            newSelectedSongs.add(songId);
        }
        setSelectedSongs(newSelectedSongs);
        setSelectedCount(newSelectedSongs.size);
    };

    return (
        <div>
            <BootstrapModal show={isModalOpen} onHide={onHide} centered dialogClassName="modal-75">
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>Add New Songs</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body style={{ height: '75vh', overflow: 'hidden' }}>
                    <Form>
                        <Form.Group className='mb-3' controlId='formSearchSong'>
                            <Form.Label>Search Songs</Form.Label>
                            <Form.Control
                                type='text'
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder='Search for a song'
                            />
                        </Form.Group>
                        <div className='mb-3'>
                            <strong>Selected Songs: {selectedCount}</strong>
                        </div>
                        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                            <Table striped bordered hover variant='dark'>
                                <thead>
                                <tr>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Select</th>
                                    <th>Title</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredSongs.slice((currentPage - 1) * songsPerPage, currentPage * songsPerPage).map((song) => (
                                    <tr key={song.id}>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', position: 'sticky', left: 0, backgroundColor: '#343a40' }}>
                                            <Form.Check
                                                type='checkbox'
                                                checked={selectedSongs.has(song.id)}
                                                onChange={() => toggleSongSelection(song.id)}
                                            />
                                        </td>
                                        <td>
                                            {song.title}
                                            <br />
                                            <audio controls>
                                                <source src={song.mp3_url} type='audio/mpeg' />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                        <Pagination className='justify-content-center'>
                            {[...Array(Math.ceil(filteredSongs.length / songsPerPage)).keys()].map((number) => (
                                <Pagination.Item
                                    key={number + 1}
                                    active={number + 1 === currentPage}
                                    onClick={() => handlePageChange(number + 1)}
                                >
                                    {number + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Form>
                </BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <Button variant='secondary' onClick={onHide}>
                        Cancel
                    </Button>
                    <Button variant='primary' onClick={handleAddSelectedSongs}>
                        Add Selected Songs
                    </Button>
                </BootstrapModal.Footer>
            </BootstrapModal>
        </div>
    );
};

export default SongSelectionModal;
