import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MdAdd, MdEdit, MdDelete } from "react-icons/all";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import Modal from 'react-modal';
import { Table, Button, Modal as BootstrapModal, Form } from 'react-bootstrap';
import {useEndpoint} from "../../../hooks/api";
import {toast} from "react-toastify";

const MusicPlaylists = () => {
    const navigate = useNavigate();
    const [playlists, setPlaylists] = useState([]);
    const [headerImage, setHeaderImage] = React.useState('/img/headers/music.png');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [newPlaylistDescription, setNewPlaylistDescription] = useState('');
    const [newPlaylistPrivacy, setNewPlaylistPrivacy] = useState('0');
    const {fetchAuth: addPlaylist} = useEndpoint("music/playlists/add");
    const {fetchAuth: fetchPlaylists} = useEndpoint("music/playlists/list");

    async function refreshPlaylists() {
        const response = await fetchPlaylists();
        if (response.error) {
            toast.error(response.error);
            return;
        }
        setPlaylists(response);
    }

    // Simulating fetching playlists data
    useEffect(() => {
        refreshPlaylists();
    }, []);

    const handleAddPlaylist = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleEditPlaylist = (id) => {
        // Navigate to edit playlist form
        navigate(`/music/playlists/edit/${id}`);
    };

    const handleDeletePlaylist = (id) => {
        // Handle deletion of a playlist (ideally should confirm before deletion)
        setPlaylists((prev) => prev.filter(playlist => playlist.id !== id));
    };

    const handleSaveNewPlaylist = () => {
        async function add()
        {
            const newPlaylist = {
                name: newPlaylistName,
                description: newPlaylistDescription,
                private: newPlaylistPrivacy
            };
            const response = await addPlaylist(newPlaylist);
            if (response.error) {
                toast.error(response.error);
                return;
            }

            setIsModalOpen(false);
            setNewPlaylistName('');
            setNewPlaylistDescription('');
            setNewPlaylistPrivacy('0');
            await refreshPlaylists();
        }

        add();
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    function content() {
        return (
            <div className="playlist-container">
                {playlists.length === 0 ? (
                    <p>No playlists available. Click the add button to create one.</p>
                ) : (
                    <Table striped bordered hover variant="dark">
                        <thead>
                        <tr>
                            <th>Playlist Name</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {playlists.map((playlist) => (
                            <tr key={playlist.id} onClick={() => navigate(`/music/playlist/${playlist.id}`)} style={{ cursor: 'pointer' }}>
                                <td><strong>{playlist.name}</strong></td>
                                <td>{playlist.description}</td>
                                <td>
                                    {playlist.isOwner && (
                                        <div className="playlist-controls">
                                            <Button variant="outline-light" size="sm" onClick={(e) => { e.stopPropagation(); handleEditPlaylist(playlist.id); }}><MdEdit /></Button>{' '}
                                            <Button variant="outline-danger" size="sm" onClick={(e) => { e.stopPropagation(); handleDeletePlaylist(playlist.id); }}><MdDelete /></Button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                )}
            </div>
        );
    }

    function rightColumn() {
        return (
            <div className="right-column-controls">
                <h4>Manage Playlists</h4>
                <p>You can create, edit, or delete playlists to organize your music.</p>
            </div>
        );
    }

    return (
        <PageHeader image={headerImage}
                    title={"Playlists"}
                    description={"Manage your music playlists effortlessly."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["Music", "/music"]
                    ]}
                    minimum={true}
                    menuleft={[
                        {icon: MdAdd, label: "Add Playlist", onClick: handleAddPlaylist},
                    ]}
                    menuright={[]}>
            {content()}
            {rightColumn()}
            <BootstrapModal show={isModalOpen} onHide={handleCloseModal} centered>
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>Add New Playlist</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formPlaylistName">
                            <Form.Label>Playlist Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={newPlaylistName}
                                onChange={(e) => setNewPlaylistName(e.target.value)}
                                placeholder="Enter playlist name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPlaylistDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={newPlaylistDescription}
                                onChange={(e) => setNewPlaylistDescription(e.target.value)}
                                placeholder="Enter description"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPlaylistPrivacy">
                            <Form.Label>Privacy</Form.Label>
                            <Form.Control
                                as="select"
                                value={newPlaylistPrivacy}
                                onChange={(e) => setNewPlaylistPrivacy(e.target.value)}
                            >
                                <option value="0">Public</option>
                                <option value="1">Private</option>
                                <option value="2">URL Only</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="primary" onClick={handleSaveNewPlaylist}>Save</Button>
                </BootstrapModal.Footer>
            </BootstrapModal>
        </PageHeader>
    );
};

export default MusicPlaylists;